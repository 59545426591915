import React, { useMemo } from "react"
import tw, { styled } from "twin.macro"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

const ParralaxBackgroundSection = ({ children, src, alt }) => {
  let { desktop, medium, small } = useStaticQuery(
    graphql`
      query {
        desktop: allFile(
          filter: { sourceInstanceName: { eq: "background-images" } }
        ) {
          nodes {
            ...BackgroundImageLarge
          }
        }
        medium: allFile(
          filter: { sourceInstanceName: { eq: "background-images" } }
        ) {
          nodes {
            ...BackgroundImageMedium
          }
        }
        small: allFile(
          filter: { sourceInstanceName: { eq: "background-images" } }
        ) {
          nodes {
            ...BackgroundImageSmall
          }
        }
      }
    `
  )

  desktop = useMemo(
    () => desktop.nodes.find((node: any) => src === node.relativePath),
    [desktop, src]
  )

  medium = useMemo(
    () => medium.nodes.find((node: any) => src === node.relativePath),
    [medium, src]
  )

  small = useMemo(
    () => small.nodes.find((node: any) => src === node.relativePath),
    [small, src]
  )

  // Art-Direction Array
  const backgroundArtDirectionStack = [
    small.childImageSharp.fluid,
    {
      ...medium.childImageSharp.fluid,
      media: `(min-width: 491px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1401px)`,
    },
  ]

  return (
    <Styles
      Tag="section"
      // style={style}
      // className={className}
      fluid={backgroundArtDirectionStack}
      backgroundColor={`#040e18`}
      title="Art-Directed Fullscreen Background"
      id="adfullscreenbg"
      role="img"
      aria-label="Art-Directed Fullscreen Background"
      preserveStackingContext={true}
    >
      {children}
    </Styles>
  )
}

const Styles = styled(BackgroundImage)`
  ${tw`
    flex
    flex-wrap
    justify-between
    w-full
    h-full
    self-center
    m-auto
    py-16
    md:py-20
    overflow-hidden
    flex-col
    place-content-center
    items-center
    max-w-none 
    px-page-gutter
    md:px-0 
    py-20 
    w-full 
    mx-0
    relative
    mb-0
    overflow-hidden
  `}

  @media (min-width: 788px) {
    height: 530px;
    overflow: hidden;
  }

  &:after {
    //creating the element
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;

    //adding the image
    background-repeat: no-repeat;

    //positioning the image
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;
    z-index: -3;
    transform: translateZ(-999px) scale(1.2);

    //mobile
    @media (max-width: 787px) {
      background-position: center center;
      background-attachment: scroll !important;
    }
  }
`

export default ParralaxBackgroundSection
